@import "./_reset.css";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: Arial;
  background-color: #292929;
  color: #2f3640;
  width: 100%;
  padding: 0;
  background-origin: padding-box;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #474747;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7f7f7f;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acacac;
}
